import { t } from 'i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Save } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from '@mui/material'

import {
  useGetUserProfileQuery,
  useGetUserSettingsQuery,
  useUpdateUserLanguageMutation,
  useUpdateUserProfileMutation,
  useUpdateUserSettingMutation,
} from '../../../../api/combined'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { HtmlContentParser } from '../../../../components/HtmlContentParser/HtmlContentParser'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import languageService from '../../../../services/LanguageService'
import utilsService from '../../../../services/UtilsService'
import { displaySnackBar } from '../../../snackbar'
import { JobFunction } from '../../types/JobFunction'
import { UserLanguage } from '../../types/User'
import { UserProfileForm } from '../../types/UserProfile'
import { UserSettingKeys } from '../../types/UserSettings'

interface UpdateUserProps {
  onProfileUpdateSuccess: () => void
}
const UpdateUser: React.FC<UpdateUserProps> = ({ onProfileUpdateSuccess }) => {
  const { t } = useTranslation()
  const { data: currentUser } = useGetUserProfileQuery()
  const [updateLanguage] = useUpdateUserLanguageMutation()
  const [selectedLanguage, setSelectedLanguage] = useState<UserLanguage>(currentUser?.language || 'en')
  const [firstName, setFirstName] = useState(currentUser?.firstName || '')
  const [lastName, setLastName] = useState(currentUser?.lastName || '')
  const [selectedJobFunction, setSelectedJobFunction] = useState<JobFunction | string>(currentUser?.jobFunction || '')
  const [newPassword, setNewPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const { data: userSettings } = useGetUserSettingsQuery()
  const passwordStrength = useMemo(() => utilsService.getPasswordStrength(newPassword), [newPassword])
  const [updateUserProfile] = useUpdateUserProfileMutation()
  const [updateUserSetting] = useUpdateUserSettingMutation()
  const dispatch = useAppDispatch()
  const [savingUser, setSavingUser] = useState(false)

  const passwordStrengthHelperText = useMemo(() => {
    const variant = 'h4'
    switch (passwordStrength) {
      case 'too-short':
        return (
          <Typography component={'span'} variant={variant} sx={{ color: (theme) => theme.palette.error.main }}>
            {t('common:password_too-short')}
          </Typography>
        )
      case 'weak':
        return (
          <Typography component={'span'} variant={variant} sx={{ color: (theme) => theme.palette.error.main }}>
            {t('common:password_weak')}
          </Typography>
        )
      case 'moderate':
        return (
          <Typography component={'span'} variant={variant} sx={{ color: (theme) => theme.palette.warning.main }}>
            {t('common:password_moderate')}
          </Typography>
        )
      case 'strong':
        return (
          <Typography component={'span'} variant={variant} sx={{ color: (theme) => theme.palette.success.main }}>
            {t('common:password_strong')}
          </Typography>
        )
      default:
        return
    }
  }, [passwordStrength, t])

  const validateTextField = (input: string) => {
    if (!input.length) return null
    return utilsService.containForbiddenChars(input) ? t('validation:invalid_special_characters') : null
  }

  const handleLanguageChange = (event: SelectChangeEvent) => {
    const value = event.target.value as UserLanguage
    setSelectedLanguage(value)
    updateLanguage(value)
  }

  const handleJobFunctionChange = (event: SelectChangeEvent) => {
    const value = event.target.value as JobFunction
    setSelectedJobFunction(value)
  }

  const handleUpdateUserInfo = useCallback(async () => {
    const firstPasswordChange = !!userSettings?.acceptedTerms
    let userProfile: UserProfileForm = {
      currentPassword: null,
      acceptTerms: true,
      firstName,
      lastName,
      jobFunction: selectedJobFunction as JobFunction,
      language: selectedLanguage,
    }

    if (newPassword) {
      userProfile.password = newPassword
      userProfile.confirmPassword = newPassword
    }

    setSavingUser(true)

    try {
      await updateUserProfile({ profile: userProfile, params: { firstPasswordChange: firstPasswordChange } }).unwrap()
      updateUserSetting({ settingKey: UserSettingKeys.acceptedTerms, value: true })

      setTimeout(() => {
        onProfileUpdateSuccess()
      }, 0)

      dispatch(displaySnackBar({ message: t('profile:profile_saved'), severity: 'success', open: true }))
    } catch (error) {
      dispatch(displaySnackBar({ message: t('profile:error_make_sure_you_entered_correct_password'), severity: 'error', open: true }))
    } finally {
      setSavingUser(false)
    }
  }, [
    firstName,
    lastName,
    newPassword,
    selectedJobFunction,
    selectedLanguage,
    updateUserProfile,
    userSettings?.acceptedTerms,
    updateUserSetting,
    onProfileUpdateSuccess,
    dispatch,
    t,
  ])

  const languagesHtml = languageService.getLanguagesArray().map((language) => {
    return (
      <MenuItem key={language.id} value={language.id}>
        {language.name}
      </MenuItem>
    )
  })

  const jobFunctionsHtml = [
    <MenuItem value="" key="empty-select"></MenuItem>,
    ...Object.values(JobFunction).map((jobFunction) => {
      return (
        <MenuItem key={jobFunction} value={jobFunction}>
          {t(`job-functions:${jobFunction}`)}
        </MenuItem>
      )
    }),
  ]

  return (
    <Box sx={{ mt: 4 }}>
      <Grid container spacing={2} sx={{ mb: 2, paddingLeft: 2 }}>
        <FormControl size="small" variant="outlined">
          <InputLabel htmlFor="language-selector-label">{t('common:language')}</InputLabel>
          <Select
            size="small"
            id="language-selector"
            labelId="language-selector-label"
            value={selectedLanguage}
            onChange={handleLanguageChange}
            label={t('common:language')}
          >
            {languagesHtml}
          </Select>
        </FormControl>
      </Grid>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item sm>
          <TextField
            name="firstName"
            type="text"
            label={t('common:user_firstname')}
            onChange={(e) => setFirstName(e.target.value)}
            variant="outlined"
            size="small"
            fullWidth
            required={!firstName.length}
            value={firstName}
            error={!!validateTextField(firstName)}
            helperText={validateTextField(firstName)}
          ></TextField>
        </Grid>
        <Grid item sm>
          <TextField
            name="lastName"
            type="text"
            label={t('common:user_lastname')}
            onChange={(e) => setLastName(e.target.value)}
            variant="outlined"
            size="small"
            fullWidth
            required={!lastName.length}
            value={lastName}
            error={!!validateTextField(lastName)}
            helperText={validateTextField(lastName)}
          ></TextField>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2, paddingLeft: 2 }}>
        <FormControl size="small" variant="outlined" fullWidth>
          <InputLabel htmlFor="job-function-selector-label">{t('common:user_job_function')}</InputLabel>
          <Select
            id="job-function-selector"
            labelId="language-selector-label"
            value={selectedJobFunction}
            onChange={handleJobFunctionChange}
            label={t('common:user_job_function')}
          >
            {jobFunctionsHtml}
          </Select>
        </FormControl>
      </Grid>

      <Divider sx={{ my: 2 }} />
      <Grid container flexDirection="column">
        <TextField
          name="password"
          type={showPassword ? 'text' : 'password'}
          label={t('common:user_new_password')}
          placeholder={t('profile:type_new_password')}
          variant="outlined"
          size="small"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          helperText={!!passwordStrength?.length && passwordStrengthHelperText}
        />
        <FormControlLabel
          sx={{ mt: 1 }}
          control={<Switch checked={showPassword} onChange={(e) => setShowPassword(e.target.checked)} />}
          label={t('common:show_password') as string}
        />
      </Grid>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Typography>
        <HtmlContentParser
          rawHtml={`${t('common:accept_terms_checkbox', {
            termsUrl: 'https://www.gamerefinery.com/terms-of-service/',
            privacyPolicyUrl: 'https://www.gamerefinery.com/privacy-policy/',
          })}`}
        ></HtmlContentParser>
      </Typography>
      <Divider sx={{ my: 2 }} />
      <div className="text-center">
        {savingUser ? (
          <GRCircularProgress />
        ) : (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            startIcon={<Save />}
            onClick={handleUpdateUserInfo}
            disabled={!selectedJobFunction || !firstName || !lastName || !newPassword || passwordStrength === 'too-short' || passwordStrength === 'weak'}
          >
            {t('common:save')}
          </Button>
        )}
      </div>
    </Box>
  )
}

/**
 * FinalizeProfileDialog
 */

const FinalizeProfileDialog: React.FC = () => {
  const { data: currentUser } = useGetUserProfileQuery()
  const { data: userSettings } = useGetUserSettingsQuery()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (userSettings?.acceptedTerms !== true || !currentUser?.jobFunction) {
      setOpen(true)
    }
  }, [currentUser?.jobFunction, userSettings?.acceptedTerms])

  return (
    <Dialog className="InviteUsersDialog" fullWidth maxWidth="sm" open={open}>
      <DialogTitle>{t('profile:finalize_profile')}</DialogTitle>
      <DialogContent className="DialogContent" dividers>
        <UpdateUser onProfileUpdateSuccess={() => setOpen(false)}></UpdateUser>
      </DialogContent>
    </Dialog>
  )
}

export default FinalizeProfileDialog
