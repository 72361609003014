import parse from 'html-react-parser'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Check, ChevronLeft, Clear } from '@mui/icons-material'
import { Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'

import { useGetFeatureExampleQuery } from '../../../../api/combined'
import { DetailedFeature } from '../../../../api/core'
import { useCurrentUserLanguage } from '../../../account/hooks/useCurrentUserLanguage'
import { useCurrentMarket } from '../../../markets'
import { ExampleExplanation } from '../../types/types'
import './FeatureDefinition.scss'

type ExplanationType = 'corrects' | 'wrongs'
interface FeatureDefinitionProps {
  feature?: DetailedFeature
}

interface ExplanationCardProps {
  explanation: ExampleExplanation
  onClick: () => void
  xs?: number
}

const ExplanationCard: React.FC<ExplanationCardProps> = ({ explanation, onClick, xs }) => {
  return (
    <Grid item xs={xs} mt={2} id={`explanation-${explanation.itemId}`}>
      <Card className="ExplanationCard" onClick={onClick}>
        <CardContent>
          <Grid container spacing={2}>
            <img className="ExplanationImage" src={explanation.imageUrl} alt=""></img>
            <Grid item xs>
              <Typography variant="h3">{explanation.title}</Typography>
              {parse(explanation.desc)}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export const FeatureDefinition: React.FC<FeatureDefinitionProps> = ({ feature }) => {
  const { t } = useTranslation()
  const { currentMarketIso: marketIso } = useCurrentMarket()
  const userLanguage = useCurrentUserLanguage()

  const [selectedExampleType, setSelectedExampleType] = useState<ExplanationType>()

  const featureId = feature?.id || ''
  const storeId = feature?.stores[0] || ''
  const { data, isFetching } = useGetFeatureExampleQuery({ featureId, storeId, marketIso, userLanguage }, { skip: !feature })

  if (isFetching || !data) {
    return (
      <Box textAlign="center" my={2}>
        <CircularProgress />
      </Box>
    )
  }
  const exampleColumnsAmount = (data.corrects?.length ? 1 : 0) + (data.wrongs?.length ? 1 : 0)

  const handleExplanationClick = (type: ExplanationType, explanationId: number) => {
    setSelectedExampleType(type)
    setTimeout(() => {
      const explanationElement = document.getElementById(`explanation-${explanationId}`)
      if (explanationElement) {
        explanationElement.scrollIntoView({ behavior: 'smooth' })
      }
    }, 0)
  }

  return (
    <Box className="FeatureModalContent">
      {!selectedExampleType ? (
        <>
          {parse(data.desc)}
          {exampleColumnsAmount > 0 && (
            <Grid container columnSpacing={3}>
              {!!data.corrects?.length && (
                <Grid item xs={exampleColumnsAmount === 1 ? 12 : 6}>
                  <Typography variant="h3" className="WhatItMeans">
                    <Check />
                    {t('feature:what_it_means')}
                  </Typography>
                  <Grid container columnSpacing={3} direction={exampleColumnsAmount === 1 ? 'row' : 'column'} wrap="wrap">
                    {data.corrects.map((explanation, index) => {
                      return (
                        <ExplanationCard
                          key={index}
                          onClick={() => handleExplanationClick('corrects', explanation.itemId)}
                          explanation={explanation}
                          xs={exampleColumnsAmount === 1 ? 6 : 12}
                        />
                      )
                    })}
                  </Grid>
                </Grid>
              )}
              {!!data.wrongs?.length && (
                <Grid item xs={exampleColumnsAmount === 1 ? 12 : 6}>
                  <Typography variant="h3" className="WhatItDoesntMean">
                    <Clear />
                    {t('feature:what_it_doesnt_mean')}
                  </Typography>
                  <Grid container columnSpacing={3} wrap="wrap">
                    {data.wrongs.map((explanation) => (
                      <ExplanationCard
                        key={explanation.imageId}
                        onClick={() => handleExplanationClick('wrongs', explanation.itemId)}
                        explanation={explanation}
                        xs={exampleColumnsAmount === 1 ? 6 : 12}
                      />
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </>
      ) : (
        <>
          <Button startIcon={<ChevronLeft />} size="large" onClick={() => setSelectedExampleType(undefined)}>
            <Typography variant="h3">{t('feature:' + (selectedExampleType === 'corrects' ? 'what_it_means' : 'what_it_doesnt_mean'))}</Typography>
          </Button>
          <Grid container direction="column" className="BiggerExplanations">
            {data[selectedExampleType].map((explanation) => (
              <ExplanationCard key={explanation.imageId} onClick={() => {}} explanation={explanation} xs={12} />
            ))}
          </Grid>
        </>
      )}
    </Box>
  )
}
