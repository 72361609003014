import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'

import { Box, Button, Divider, Typography } from '@mui/material'

import {
  Tag,
  useFinalizeAnalysisMutation,
  useGetAnalysisNotesQuery,
  useGetCategoriesFeaturesQuery,
  useGetCategoriesMapQuery,
  useGetGameAndAnalysisQuery,
  useGetGameLatestAnalysisQuery,
  useGetGameLatestAndPrevAnalysisQuery,
  useGetRefreshAnalysisQuery,
} from '../../api/core'
import GRCircularProgress from '../../components/GRCircularProgress/GRCircularProgress'
import { LinearProgressBar } from '../../components/LinearProgressBar/LinearProgressBar'
import { useSoftLaunchAccessCheck } from '../../features/account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../features/account/hooks/useCurrentUserLanguage'
import useGetGameAnalyzedStatus from '../../features/analysis/hooks/useGetGameAnalyzedStatus'
import { Analysis } from '../../features/analysis/types/Analysis'
import { Game, GameLink } from '../../features/game'
import FeatureSetRow from '../../features/game-analyzer/components/FeatureSetRow/FeatureSetRow'
import FilterFeatureCard from '../../features/game-analyzer/components/FilterFeatureCard/FilterFeatureCard'
import FilterTagList from '../../features/game-analyzer/components/FilterTagList/FilterTagList'
import GameAnalysisCard from '../../features/game-analyzer/components/GameAnalysisCard/GameAnalysisCard'
import PowerScoreAnalysisDialog from '../../features/game-analyzer/components/PowerScoreAnalysisDialog/PowerScoreAnalysisDialog'
import PowerScoreProgressDialog from '../../features/game-analyzer/components/PowerScoreProgressDialog/PowerScoreProgressDialog'
import useGetAnswerProgress from '../../features/game-analyzer/hooks/useAnswerProgress'
import { GameAnalyzerBenchmarkParams } from '../../features/game-analyzer/types/GameAnalyzerBenchmarkParams'
import GameSearchDialog, { GameWithMarketIso } from '../../features/game-search/components/GameSearchDialog/GameSearchDialog'
import { GameSearchDialogTab, GameSearchDialogType } from '../../features/game-search/types/GameSearchDialogConfig'
import GameFeatureIdeasModal from '../../features/game/components/GameFeatureIdeasModal/GameFeatureIdeasModal'
import GameHeader from '../../features/game/components/GameHeader/GameHeader'
import { useGameAndAnalysis, useOwnGameCheck } from '../../features/game/hooks/gameHooks'
import { useCurrentMarket } from '../../features/markets'
import { displaySnackBar } from '../../features/snackbar'
import { useAppDispatch } from '../../hooks/storeHooks'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import { useSearchParams } from '../../hooks/useSearchParams'
import analyticsService from '../../services/AnalyticsService'
import PageService from '../../services/PageService'
import './GameAnalyzerDetailPage.scss'

/**
 * GameAnalyzerDetailPage Show detail of game page
 */
interface GameAnalyzerDetailPageProps {}
const GameAnalyzerDetailPage: React.FC<GameAnalyzerDetailPageProps> = () => {
  usePage(PageService.getPageWithId('game-analyzer-detail'), 'Analysis tool: Started analysis')

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const userLanguage = useCurrentUserLanguage()

  const { id } = useParams<{ id: string }>()
  const gameId = id || ''
  const { data: analysisNotes } = useGetAnalysisNotesQuery({ gameId })

  const { currentMarketIso: mainMarketIso } = useCurrentMarket()

  const { gameAndAnalysis, isLoading: gameAndAnalysisIsLoading } = useGameAndAnalysis(gameId, mainMarketIso)
  const [gameAnalyzedStatus] = useGetGameAnalyzedStatus(gameAndAnalysis?.game)
  const [selectedBenchmarkGamesWithIso, setSelectedBenchmarkGamesWithIso] = useState<GameWithMarketIso | undefined>(undefined)
  const isOwnGame = useOwnGameCheck(gameAndAnalysis && gameAndAnalysis.game)

  usePage(PageService.getPageWithId('game-analyzer-detail'), 'Visited Game Analyzer')
  useDocumentTitle(t('sidebar:game_analyzer') + (gameAndAnalysis?.game ? ': ' + gameAndAnalysis.game.resolvedName : ''))

  const { data: currentAnalysisData } = useGetGameLatestAnalysisQuery({ id: gameId, marketIso: mainMarketIso })
  const { data: freshCurrentAnalysisData } = useGetRefreshAnalysisQuery(
    { id: currentAnalysisData ? currentAnalysisData.id : '', gameId },
    { skip: !currentAnalysisData }
  )

  const { data: latestAndPrevAnalysis } = useGetGameLatestAndPrevAnalysisQuery({ id: gameId, marketIso: mainMarketIso, onlyCompleted: false })
  const { data: categoriesMaps } = useGetCategoriesMapQuery({ marketIso: mainMarketIso })

  const [toggleView, setToggleView] = useState<{ [key: string]: boolean }>({})
  const { data: features, isLoading: isCategoriesFeaturesLoading } = useGetCategoriesFeaturesQuery(
    {
      marketIso: mainMarketIso,
      categories: categoriesMaps ? Object.keys(categoriesMaps) : [],
    },
    { skip: !categoriesMaps }
  )

  const hasAccessToSoftLaunch = useSoftLaunchAccessCheck()
  const [gameSearchModalOpen, setGameSearchModalOpen] = useState(false)
  const gameSearchDialogConfig = useMemo(() => {
    return {
      type: GameSearchDialogType.GameBenchmark,
      tabs: hasAccessToSoftLaunch ? [GameSearchDialogTab.Search, GameSearchDialogTab.SoftLaunch] : [GameSearchDialogTab.Search],
    }
  }, [hasAccessToSoftLaunch])

  const gameIsAnalyzedForMarket = useMemo(() => {
    if (!gameAndAnalysis || !gameAndAnalysis?.game.gpsPerMarket[mainMarketIso]) return false

    return gameAndAnalysis.game.gpsPerMarket[mainMarketIso] > 0 ? true : false
  }, [gameAndAnalysis, mainMarketIso])

  useEffect(() => {
    if (!!currentAnalysisData && !!gameAndAnalysis) {
      analyticsService.trackEvent('Analysis tool: Started analysis', {
        data: {
          analysisId: currentAnalysisData?.id,
          gameId,
          gameName: gameAndAnalysis?.game.resolvedName,
        },
      })
    }
  }, [currentAnalysisData, gameAndAnalysis, gameId])

  const { parsedParams, setSearchParams } = useSearchParams<GameAnalyzerBenchmarkParams, GameAnalyzerBenchmarkParams>()
  const { data: benchmarkGameAndAnalysisData } = useGetGameAndAnalysisQuery(
    { id: parsedParams.comparedGame || '', marketIso: parsedParams.comparedGameMarketIso || mainMarketIso, tags: true, userLanguage },
    { skip: !parsedParams.comparedGame }
  )

  const selectedBenchmarkGameAndAnalysis = useMemo(() => {
    if (!parsedParams.comparedGame && !parsedParams.comparedGameMarketIso) return undefined
    if (!benchmarkGameAndAnalysisData) return undefined

    return {
      game: benchmarkGameAndAnalysisData.game,
      analysis: benchmarkGameAndAnalysisData.analysis,
    }
  }, [benchmarkGameAndAnalysisData, parsedParams.comparedGame, parsedParams.comparedGameMarketIso])

  useEffect(() => {
    const gameId = selectedBenchmarkGameAndAnalysis?.game?.id
    const marketIso = selectedBenchmarkGameAndAnalysis?.analysis?.marketIso

    if (!gameId || !marketIso) {
      setSelectedBenchmarkGamesWithIso(undefined)
      return
    }

    const gamesWithMarketIso: GameWithMarketIso = {
      [gameId]: [marketIso],
    }

    setSelectedBenchmarkGamesWithIso(gamesWithMarketIso)
  }, [selectedBenchmarkGameAndAnalysis, mainMarketIso])

  const [powerScoreProgressBarOpen, setPowerScoreProgressBarOpen] = useState(false)
  const [powerScoreAnalysisOpen, setPowerScoreAnalysisOpen] = useState(false)
  const [powerScoreUpdateProcess, setPowerScoreUpdateProcess] = useState(0)
  const [finalizeAnalysis] = useFinalizeAnalysisMutation()

  const [showFeatureIdeas, setShowFeatureIdeas] = useState(false)
  const [filterTagList, setFilterTagList] = useState<Tag[]>([])

  const [answerProgress] = useGetAnswerProgress(freshCurrentAnalysisData, categoriesMaps)
  const areAllCategoryCompleted = answerProgress === 100

  const onBenchmarkGameClose = (game: Game[] | undefined, gamesWithMarketIso: GameWithMarketIso | undefined, reason: string | undefined) => {
    setGameSearchModalOpen(false)

    if (reason === 'cancel') return

    if (!game || !gamesWithMarketIso) {
      onBenchmarkGameClear()
      return
    }

    // get compared game market iso from gamesWithMarketIso it's first index of array
    const [selectedGame] = game
    const [compareGameMarketIso] = gamesWithMarketIso[selectedGame.id]

    if (!selectedGame) return

    const message = (
      <Typography component="span">
        <Trans i18nKey={'select-game:selected_game'} values={{ selectedGame: selectedGame.resolvedName }} />
      </Typography>
    )
    dispatch(displaySnackBar({ message, severity: 'success', open: true }))
    setSearchParams({ comparedGame: selectedGame.id, comparedGameMarketIso: compareGameMarketIso })
    setSelectedBenchmarkGamesWithIso(gamesWithMarketIso)
  }

  const onBenchmarkGameClear = () => {
    setSearchParams({ comparedGame: undefined, comparedGameMarketIso: undefined })
    setSelectedBenchmarkGamesWithIso(undefined)
    setGameSearchModalOpen(false)
  }

  const updatePowerScore = async () => {
    try {
      if (!freshCurrentAnalysisData) return

      setPowerScoreProgressBarOpen(true)
      const progressMilestones = [0, 20, 50, 70, 80, 90]
      progressMilestones.forEach((milestone, index) => {
        setTimeout(() => {
          setPowerScoreUpdateProcess(milestone)
        }, index * 4000)
      })

      const response = (await finalizeAnalysis({ id: freshCurrentAnalysisData.id, marketIso: mainMarketIso, gameId, type: 'gpschange' })) as {
        data?: Analysis
        error?: Object
      }

      if (!!response.error) {
        dispatch(displaySnackBar({ message: t('analysis:error_calculating_gps'), severity: 'error', open: true }))
        setPowerScoreProgressBarOpen(false)
        return
      }

      if (!latestAndPrevAnalysis?.latest) {
        analyticsService.trackEvent('Game Analysis: Features Completed', {
          data: { analysisId: response.data?.id, gameId: gameAndAnalysis?.game.id, gameName: gameAndAnalysis?.game.resolvedName },
        })
      }

      setPowerScoreUpdateProcess(100)

      setPowerScoreProgressBarOpen(false)
      setPowerScoreAnalysisOpen(true)
      setPowerScoreUpdateProcess(0)
    } catch (err) {
      dispatch(displaySnackBar({ message: t('analysis:error_calculating_gps'), severity: 'error', open: true }))
      setPowerScoreProgressBarOpen(false)
    }
  }

  const featureContents = () => {
    if (!categoriesMaps || !features || !currentAnalysisData || !freshCurrentAnalysisData || !latestAndPrevAnalysis) return null

    let refs: Array<React.MutableRefObject<HTMLDivElement | null>> = []
    const categories = Object.values(categoriesMaps)

    const hasFilterTag = !!filterTagList.length
    if (hasFilterTag) {
      return (
        <FilterFeatureCard
          notes={analysisNotes || {}}
          filterTagList={filterTagList[0]}
          categoriesMap={categoriesMaps}
          categoriesFeatures={features}
          currentAnalysis={freshCurrentAnalysisData}
          latestAnalysis={latestAndPrevAnalysis.latest}
          benchMarkAnalysis={selectedBenchmarkGameAndAnalysis?.analysis}
          gameAnalyzedStatus={gameAnalyzedStatus}
        />
      )
    }

    return categories.map((category, index) => (
      <div key={category.id}>
        <GameAnalysisCard
          notes={analysisNotes || {}}
          open={toggleView[category.id]}
          isLoading={isCategoriesFeaturesLoading}
          category={category}
          gameAnalyzedStatus={gameAnalyzedStatus}
          currentAnalysis={freshCurrentAnalysisData}
          latestAnalysis={latestAndPrevAnalysis.latest}
          benchMarkAnalysis={selectedBenchmarkGameAndAnalysis?.analysis}
          features={features[category.id]}
          onViewToggle={() => {
            setToggleView({ ...toggleView, [category.id]: !toggleView[category.id] })
          }}
          showNextFeatureBtn={index < categories.length - 1}
          onNextFeatureClicked={async () => {
            const nextCardId = categories[index + 1].id
            await setToggleView({ ...toggleView, [nextCardId]: true })

            const nextCardRef = refs[index + 1]
            nextCardRef.current?.scrollIntoView({ behavior: 'smooth' })
          }}
          onRefBind={(ref) => {
            refs = [...refs, ref]
          }}
        />
      </div>
    ))
  }

  if (gameAndAnalysisIsLoading || !gameAndAnalysis) return <GRCircularProgress />

  return !isOwnGame ? (
    <Navigate replace to="/game-analyzer" />
  ) : (
    <>
      {/* dialogs */}
      <GameSearchDialog
        gameSearchDialogConfig={gameSearchDialogConfig}
        modalOpen={gameSearchModalOpen}
        onClose={onBenchmarkGameClose}
        onClearGameList={onBenchmarkGameClear}
        defaultSelectedGames={selectedBenchmarkGameAndAnalysis?.game ? [selectedBenchmarkGameAndAnalysis?.game] : undefined}
        defaultGamesWithMarketIso={selectedBenchmarkGamesWithIso}
        enableMarketSelector
        hideSetAsDefault
        onlyGamesWithPowerscore
        hasDefaultSubgenre
      />
      <PowerScoreProgressDialog process={powerScoreUpdateProcess} open={powerScoreProgressBarOpen}></PowerScoreProgressDialog>
      <GameFeatureIdeasModal
        open={showFeatureIdeas}
        onClose={() => setShowFeatureIdeas(false)}
        game={gameAndAnalysis.game}
        analysis={latestAndPrevAnalysis?.latest}
      />
      <PowerScoreAnalysisDialog
        open={powerScoreAnalysisOpen}
        game={gameAndAnalysis.game}
        categoriesMap={categoriesMaps}
        onClose={() => setPowerScoreAnalysisOpen(false)}
        features={features}
      />

      <GameHeader gameAndAnalysis={{ game: gameAndAnalysis.game, analysis: latestAndPrevAnalysis?.latest }} isLoading={gameAndAnalysisIsLoading}>
        <GameHeader.Actions>
          <GameLink game={gameAndAnalysis.game} disabled={!areAllCategoryCompleted || !gameIsAnalyzedForMarket}>
            <Button variant="contained" color="primary" disabled={!areAllCategoryCompleted || !gameIsAnalyzedForMarket}>
              {t('analysis:show_full_analysis')}
            </Button>
          </GameLink>
          <Button disabled={!gameIsAnalyzedForMarket} variant="contained" color="primary" onClick={() => setShowFeatureIdeas(true)}>
            {t('analysis:show_feature_ideas')}
          </Button>
          <Button variant="contained" color="success" disabled={!areAllCategoryCompleted} onClick={() => updatePowerScore()}>
            {t('analysis:update_gps')}
          </Button>
        </GameHeader.Actions>
        <GameHeader.Content>
          <Box sx={{ pl: 2, my: 2 }}>
            <LinearProgressBar labelAfter={answerProgress ? `${answerProgress}%` : ' '} variant="determinate" value={answerProgress} />
          </Box>
        </GameHeader.Content>
      </GameHeader>

      <Box sx={{ mt: 3 }}>
        <Divider />
        <Box sx={{ mt: 3 }}>
          <FilterTagList onTagsSelected={(tags) => setFilterTagList(tags)} />
        </Box>
      </Box>

      <Divider />

      <FeatureSetRow
        analysis={latestAndPrevAnalysis?.latest}
        game={gameAndAnalysis.game}
        title={!filterTagList.length ? t('common:feature_set') : t('analysis:filtered_features')}
        benchmarkGame={selectedBenchmarkGameAndAnalysis?.game}
        benchMarkAnalysis={selectedBenchmarkGameAndAnalysis?.analysis}
        onBenchmarkGameClick={() => setGameSearchModalOpen(true)}
        onUpdatePowerScoreClick={() => updatePowerScore()}
        answerProgress={answerProgress}
      />

      {featureContents()}
    </>
  )
}

export default GameAnalyzerDetailPage
