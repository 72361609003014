import React, { useMemo } from 'react'

import { CategoriesFeatures, CategoriesMap, Feature, Tag } from '../../../../api/core'
import { Analysis } from '../../../analysis/types/Analysis'
import { AnalysisNotes } from '../../../analysis/types/AnalysisNotes'
import { GameAnalysisStatus } from '../../../game/types/GameAnalysisStatus'
import { getCategoryEffect } from '../../utils/getCategoryEffect'
import FeatureCard from '../FeatureCard/FeatureCard'
import FeatureCardWrapper from '../FeatureCardWrapper/FeatureCardWrapper'

/**
 * FilterFeatureCard Card used to show filtered features in game analyzer
 */
interface FilterFeatureCardProps {
  notes: AnalysisNotes
  categoriesMap: CategoriesMap
  categoriesFeatures: CategoriesFeatures
  latestAnalysis?: Analysis // Can be also null
  currentAnalysis: Analysis
  benchMarkAnalysis?: Analysis
  filterTagList: Tag
  gameAnalyzedStatus?: GameAnalysisStatus
}
const FilterFeatureCard: React.FC<FilterFeatureCardProps> = ({
  notes,
  currentAnalysis,
  latestAnalysis,
  benchMarkAnalysis,
  categoriesFeatures,
  categoriesMap,
  filterTagList,
  gameAnalyzedStatus,
}) => {
  const filteredFeatures = useMemo(
    () =>
      Object.values(categoriesFeatures).reduce<Feature[]>((result, features) => {
        features.forEach((feature) => {
          if (feature.tags.includes(filterTagList.id)) {
            result.push(feature)
          }
        })
        return result
      }, []),
    [categoriesFeatures, filterTagList.id]
  )

  const getAnalysisAnswer = (analysis: Analysis | undefined, categoryId: string, featureId: string) => {
    if (analysis && !!analysis.answers && !!analysis.answers[categoryId]) {
      return analysis.answers[categoryId][featureId]
    }
  }

  return (
    <FeatureCardWrapper>
      {filteredFeatures.map((feature) => (
        <FeatureCard
          note={notes[feature.legacyId]}
          currentAnswer={getAnalysisAnswer(currentAnalysis, feature.categoryId, feature.id)}
          latestAnswer={getAnalysisAnswer(latestAnalysis, feature.categoryId, feature.id)}
          benchmarkAnswer={getAnalysisAnswer(benchMarkAnalysis, feature.categoryId, feature.id)}
          key={feature.id}
          feature={feature}
          analysisId={currentAnalysis.id}
          latestScore={latestAnalysis ? getCategoryEffect(categoriesMap[feature.categoryId], latestAnalysis, feature) : 0}
          benchmarkScore={benchMarkAnalysis && getCategoryEffect(categoriesMap[feature.categoryId], benchMarkAnalysis, feature)}
          category={categoriesMap[feature.categoryId]}
          gameAnalyzedStatus={gameAnalyzedStatus}
        />
      ))}
    </FeatureCardWrapper>
  )
}

export default FilterFeatureCard
