import { Screenshot, SimpleFeature } from '../../../api/core'
import { Analyst } from '../../../types/Analyst'
import { VersionInfo } from '../../../types/VersionInfo'
import { VersionTag } from '../../../types/VersionTag'
import { UserLanguage } from '../../account/types/User'
import { GameReview } from '../../game/types/GameReview'
import { GameStageId } from '../../game/types/GameStageId'
import type { Duration, EventComment } from '../../live-events/types/TrackingEvents'

export interface UnifiedNewsEntriesNewsData {
  lastEvents?: boolean
  newsData: UnifiedNewsEntry[]
}
export interface UnifiedNewsEntry {
  feedEntry: NewsEntry
  comment?: any
  history?: any
  dhistory?: any
  hasFTUEVideo: number
  revenueDownloadsData?: {
    percentageIncreaseRevenue: number
    percentageIncreaseDownload: number
    sum14BeforeRevenue: number
    sum14BeforeDownloads: number
    sum14AfterRevenue: number
    sum14AfterDownloads: number
    history?: {
      [timestamp: string]: {
        country: string
        revenue: number
        downloads: number
      }
    }
  }
  review?: GameReview
  versionInfo?: VersionInfo
}

export interface NewsEntryFeature {
  featureName: string
  featureName_translation: string
  choice2LegacyId: number
  featureLegacyId: number
  choice1Id: string
  choice1LegacyId: number
  featureId: string
  choice1: string
  choice2: string
  choice2Id: string
  choice1_translation: string
  choice2_translation: string
}

export interface NewsEntry {
  id: string
  publishedAt: number
  type: NewsEntryType
  title?: string
  titles: {
    [language in UserLanguage]?: string
  }
  subtitle?: string
  subtitles: {
    [language in UserLanguage]?: string
  }
  entryData: {
    appId: number
    icon: string
    game: string
    gameId: string
    gameVersion: string
    artist: string
    conventionalSubgenre?: string
    conventionalSubgenreId?: string
    rank: number
    versionTags?: Array<VersionTag>
    organizationId?: string
    image: {
      id: string
      url: string
      mimeType: string
    }
    content: {
      [language in UserLanguage]?: string
    }
    action: {
      label: {
        [language in UserLanguage]?: string
      }
      type: string
      target: string
    }
    versionReleaseDate?: number
    features?: Array<NewsEntryFeature>
    screenshots?: Array<NewsEntryScreenshot>
    softlaunchMarkets?: Array<string>
    similarGames?: Array<NewsEntrySimilarGame>
    stageId?: GameStageId
    gps?: number
    gpsBefore?: number
    gpsChange?: number
    commentPublished?: boolean
    commentId?: string
    typeId: string
    eventId?: string
    analyst?: Analyst
    comment?: EventComment
    durations?: Duration[]
    start?: number
    end?: number
    screenshotUrls?: string[]
    sticky?: boolean
    version?: string
  }
  gameId: string
  market?: string
  baseType?: string
}

export interface NewsEntryScreenshot extends Omit<Screenshot, 'features'> {
  features?: NewsEntryScreenshotFeature[]
}

export interface NewsEntryScreenshotFeature extends SimpleFeature {
  featureName_translation: string
  choiceName_translation: string
}

export interface NewsEntrySimilarGame {
  gameId: string
  distance: number
  artist: string
  appId: number
  name: string
  icon: null
  icons: string[]
}

export enum NewsEntryType {
  EntryTop10 = 'entrytop10',
  EntryTop20 = 'entrytop20',
  EntryTop50 = 'entrytop50',
  EntryTop100 = 'entrytop100',
  EntryTop200 = 'entrytop200',
  Editorial = 'editorial',
  GpsNew = 'gpsnew',
  GpsOutside = 'gpsoutside',
  GpsUpdate = 'gpsupdate',
  GpsSystem = 'gpssystem',
  MajorGameRelease = 'majorgamerelease',
  PerformanceChangeRevenue = 'performancechangerevenue',
  PerformanceChangeDownload = 'performancechangedownload',
  SoftLaunch = 'softlaunch',
  SoftLaunchNonAnalyzed = 'softlaunchnonanalyzed',
  LiveEventNewEventType = 'live_event_new_event_type',
  LiveEventAnalystNotes = 'live_event_analyst_notes',
}
