import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'

import { Chip, Stack } from '@mui/material'

import { useGetUserProfileQuery } from '../../api/combined'
import { RoleEnum } from '../../features/account'
import { useIsOnFreePlan } from '../../features/account/hooks/userHooks'
import { useLastSeenNewsCount } from '../../features/news/hooks/newsHooks'
import { useGetReportsUnreadCount } from '../../features/reports/hooks/reportsHooks'
import { useAppSelector } from '../../hooks/storeHooks'
import { Page } from '../../types/Page'
import { SideNavigationGroupData } from '../../types/SideNavigationGroupData'
import './SideNavigationGroup.scss'

interface Props {
  group: SideNavigationGroupData
}

const SideNavigationGroup: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const location = useLocation()

  const isOnFreePlan = useIsOnFreePlan()

  const currentPage: Page | undefined = useAppSelector((state) => {
    return state.currentPage
  })

  let currentGroupId = currentPage?.groupId

  // Assign groupId based on specific currentPage.id values
  if (!currentGroupId || currentGroupId === '') {
    if (currentPage?.id === 'game') {
      currentGroupId = 'game-analysis'
    } else if (currentPage?.id === 'game-analyzer-detail') {
      currentGroupId = 'game-analysis'
    } else if (['market-share', 'market-explorer', 'live-events-tracker'].includes(currentPage?.id || '')) {
      currentGroupId = 'market-research'
    } else if (['compare-games', 'game-analyzer', 'implementation-examples', 'game-analysis'].includes(currentPage?.id || '')) {
      currentGroupId = 'game-analysis'
    }
  }

  const { data: currentUser } = useGetUserProfileQuery()
  const { data: lastSeenNewsCount } = useLastSeenNewsCount()
  const lastSeenReportsCount = useGetReportsUnreadCount()

  var i = 0
  const pagesHtml = props.group.pages
    .filter((page) => {
      if (!currentUser) return false
      return sideNavigationItemFilter(page, currentUser.roles)
    })
    .map((page: Page) => {
      i++

      const isCurrentRoute = (page.startUrl && location.pathname.startsWith(page.startUrl)) || location.pathname.startsWith(page.href)
      const pathname = page.startUrl ? page.startUrl : page.href

      return (
        <Stack direction="row" display="flex" justifyContent="space-between" alignContent="center" key={i} maxHeight="28px">
          <NavLink
            to={isCurrentRoute ? { ...location, pathname } : pathname}
            className={({ isActive }) => (isActive || isCurrentRoute ? 'side-navi-item side-navi-item--active' : 'side-navi-item')}
            aria-label={t(page.localizationKey)}
          >
            <>
              {page.iconUrl ? <img className="side-navi-item__icon" src={page.iconUrl} alt="" /> : null}
              {page.iconComponent ? <page.iconComponent className="side-navi-item__mui-icon" /> : null}
              {t(page.localizationKey)}
            </>
          </NavLink>

          {page.id === 'daily-insights' && lastSeenNewsCount !== undefined && lastSeenNewsCount > 0 && (
            <Chip color="primary" label={lastSeenNewsCount} className="badge-item" />
          )}

          {!isOnFreePlan && page.id === 'reports' && lastSeenReportsCount !== undefined && lastSeenReportsCount > 0 && (
            <Chip color="primary" label={lastSeenReportsCount} className="badge-item" />
          )}
        </Stack>
      )
    })

  return (
    <div className="SideNavigationGroup side-navi-group">
      <NavLink
        to={props.group.pages.length ? props.group.pages[0].href : ''}
        className={currentGroupId === props.group.id ? 'side-navi-header side-navi-header--active' : 'side-navi-header'}
      >
        {t(props.group.localizationKey)}
      </NavLink>
      {pagesHtml}
    </div>
  )
}

export default SideNavigationGroup

/**
 * Filter function for side navigation items based on user roles
 */
export const sideNavigationItemFilter = (page: Page, userRoles: RoleEnum[]) => {
  const roleRequiredByPage = page.hideWithoutRole
  if (!roleRequiredByPage) {
    return true
  } else if (typeof roleRequiredByPage === 'string') {
    return userRoles.includes(roleRequiredByPage)
  } else if (Array.isArray(roleRequiredByPage)) {
    return roleRequiredByPage.some((role) => userRoles.includes(role))
  }
}
